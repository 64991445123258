
const contactConfig = {
    YOUR_EMAIL: 'rl@thedepoappcompany.com',
    YOUR_FONE: '+267-246-8849',
    YOUR_ADDRESS: 'PO Box 11344 Philadelphia, pa, 19137',
    description:
        'We are in beta if you encounter any emergencies call us directly at +267-246-8849 or\n' +
        'fill in the form with details and we will contact you within 48 hours ',
    YOUR_SERVICE_ID: 'service_id',
    YOUR_TEMPLATE_ID: 'template_id',
    YOUR_USER_ID: 'user_id',
};


export {

    contactConfig

};