import React from "react";

import Footer from "../../Footer";

import Our_App_Content from "./Our_App_Content";

function Our_App(){
    return(
        <>

            <Our_App_Content/>
            <Footer/>



        </>
    )

}


export default Our_App;