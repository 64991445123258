import React from "react";
import "./Lawyers.css";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.css';





import first_banner from '../img/banner_3.jpg'
import lawyer_phone from '../img/lawyer_phone.jpg'
import analytic_pic from '../img/lawyer_analytics.jpg'
import lawyer_assistance_pic from '../img/lawyer_assistance.jpg'
import lawyer_files_pic from '../img/lawyer_files.jpg'






function Lawyers_Content() {
    return(
        <Container fluid style={{overflow: "hidden"}} className="test">

            <div className="row">

            <div>
                <Card style={{border:"none"}}>
                <img src={first_banner} alt='Guy with laptop' className="lawyer_banner_1 "/>
                <h1 className="first_head">Lawyer Actions</h1>
                    <div className="box1"></div>
                </Card>
            </div>
            </div>


            <div>
                <Card className="card1">
                <h1 className="second_head">For Our Lawyers</h1>

                <p className="first_p">It's well known that litigation involves a vast amount of discovery through depositions. Accurate documentation relies dependable high quality deposition support providers like court reporters, interpreters, <br className='disappear'/> videographers, and video technicians.
                    Traditionally these professionals worked independently and for a myriad of agency providers and this logistically increases the cost to you the lawyer. <br className='appear'/><br className='appear'/> We've created <br className='disappear'/> an app that handles the difficult work of finding the right person at the right time.
                    We give you the best pool of deposition provider talent all in a single place to hire, receive delivery, and pay. Whether you <br className='disappear'/>are a solo practitioner, large law firm, or in-house counsel,
                    if you have a deposition DepoApp.com is the best place and value for your litigation budget. It's free to use and you will realize savings of at least <br className='disappear'/> 10%. <br className='appear'/><br className='appear'/>
                    From professional litigation transcripts to litigation deposition video and editing, to court room playback services, our app will provide you with the most efficient and cost-effective solution for finding the <br className='disappear'/>
                    right deposition support services to your unique needs, no matter how large or small.</p>
                </Card>
            </div>

            <div>
                <Card className="card4">
                    <h1 className="third_head">Why Should Lawyers Use The Depo App?</h1>
                    <p className="second_p">The litigation support specialists serving our lawyers will be well vetted and we will implement
                        a grading<br className='disappear'/>
                        system that will assure you get what your looking for. This is only the start to our plans to provide cutting-edge <br className='disappear'/>
                        technical advancements in the industry that can seamlessly integrate and augment your litigation teams needs. <br className='disappear'/>
                        We are proudly providing access to our beta testing group in the Tri-State area, and based in Philadelphia, PA. <br className='disappear'/>
                        We are accepting interested beta test participants in the eastern PA, NJ, DE, and NY areas. If you would like to <br className='disappear'/> jump in and see what we have cooking then apply below</p>

                    <button className="app_store">In The App Store</button>
                    <button className="play_store">In The Play Store</button>
                    <p className="second_p_f">The Depo App will become the place for your team to easily manage dedicated litigation and trial support specialists <br className='disappear'/>
                        to ensure that you receive what you need on time and with precise attention to detail. Our goal is to handle the <br className='disappear'/>
                        technology so that you can focus your attention on winning your case.</p>

                    <img className="lawyer_phone" src={lawyer_phone} alt="lawyer with phone"/>
                </Card>

            </div>

            <div>
                <Card className="card2">
                    <h1 className="lawyer_tools_header">Unique Tools For Our Lawyers</h1>
                    <h2 className="first_subheader">Deposition Cost Analytics</h2>
                    <p className="first_subp">One service allows lawyers and law firms to keep track of the deposition costs associated with their cases. This is <br className='disappear'/>
                        invaluable in large cases with many witnesses and a multitude of different providers doing the support services. <br className='disappear'/>
                        These deposition analytics gives attorneys and law firms valuable insight into the return on deposition investments. <br className='disappear'/>
                        Lawyers can request a myriad of analytics directly from us at any time.</p>
                    <img className='analytic_pic' src={analytic_pic} alt='picture of data'/>

                </Card>
            </div>

            <div>
                <Card className="card2">
                    <h2 className="second_subheader">Deposition Assistance</h2>
                    <p className="second_subp">The deposition support specialists using our app will be expected to be committed to assisting attorneys with any <br className='disappear'/>
                        challenges presented by deposition transcripts and or video. We will be adding video technicians to our talent pool <br className='disappear'/>
                        very soon for trial services. Currently our beta product only allows lawyers to find court reporters. We are already <br className='disappear'/>
                        expanding the functionality of our application to offer interpreters, videographers, and video technicians. You'll have <br className='disappear'/>
                        someone, readily available to offer courtroom services and on the street services like "day in life" videos to bolster your case.</p>
                    <img className='assistance_pic' src={lawyer_assistance_pic} alt='team of people'/>

                </Card>
            </div>

            <div>
                <Card className="card2">
                    <h2 className="third_subheader">Exhibit Management</h2>
                    <p className="third_subp">With our app your not dealing with multiple parities across a case maintaining chain of custody and allowing for ease of <br className='disappear'/>
                        exhibit management. By being all in one place the services you find through our application helps attorneys identify and <br className='disappear'/>
                        process relevant exhibits for potential witnesses in direct and cross-examinations. Document, audio, and video files and <br className='disappear'/>
                        data can be requested in the formats you will need. In addition to our deposition specialists holding backups as required <br className='disappear'/>
                        by law our company will keep files for at least a year for our attorneys to damage and process their deposition assets.</p>
                    <img className='files_pic' src={lawyer_files_pic} alt='picture of document'/>

                </Card>
            </div>

            <div>
                <Card className="card3">
                    <h1 className="fourth_head">Additional Services For The Future</h1>
                    <h2 className="fourth_subheader">Digital Forensics</h2>
                    <h2 className="fifth_subheader">E-Discovery</h2>
                    <h2 className="sixth_subheader">Web 3 Enhancements</h2>

                </Card>
            </div>

        </Container>
    )

}



export default Lawyers_Content