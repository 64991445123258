import React from "react";



import Footer from "../../Footer";

import Lawyer_Content from "./Lawyers_Content";


function Lawyer(){
    return(
        <>
            <Lawyer_Content/>
            <Footer/>










        </>
    )
}

export default Lawyer;