import React from "react";



import Footer from "../../Footer";

import Terms_Of_Use_Content from "./Terms_Of_Use_Content";


function Terms_Of_Use(){
    return(
        <>
            <Terms_Of_Use_Content/>
            <Footer/>










        </>
    )
}

export default Terms_Of_Use;