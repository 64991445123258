import React from "react";

import Footer from "../../Footer";

import Tech_Support_Content from "./Tech_Support_Content";
import FAQ from "./FAQ";


function Tech_Support(){
    return(
        <>
            <Tech_Support_Content/>
            <FAQ/>
            <Footer/>



        </>
    )

}


export default Tech_Support
