import React from "react";

import first_banner from '../img/banner_3.jpg'
import './Freelancer.css';
import {Card} from "react-bootstrap";

import Container from "react-bootstrap/Container";



function Freelancers_Content(){
    return(
        <>
            <Container fluid style={{overflow: "hidden"}}>

                <div className="row">

                    <div>
                        <Card style={{border:"none"}}>
                            <Card.Img src={first_banner} alt='Guy with laptop' className="banner_1 "/>
                            <h1 className="first_head">Freelancer Actions</h1>
                        </Card>
                    </div>
                </div>

                <div>
                    <Card className="card1">
                        <h1 className="second_head">For Our Freelancers</h1>

                        <p className="first_p">Court reporters are an important part of any legal proceedings. Their job entails producing a valid and accurate real-time report of a court proceeding, deposition, trial, prosecution or administrative hearing. Whether you are looking for a reliable certified court reporter or you want to be one, it is important to know the top qualities and skills that a court reporter must have.

                            Like a secretary who records the minutes of a meeting, the court reporters in Atlanta GA also keep a record of a legal proceeding. Though both these professionals have an almost similar task, the job description of the later is far more complex.

                            Court reporters provide a reliable reference for the lawyers of both parties, as well as for the judges, and jury. Apart from the typical written or encoded transcription, some court reporting services firms offer sign language services to provide translation for participants with hearing impairment.</p>
                    </Card>
                </div>

                <div>
                    <Card className="card4">
                        <h1 className="third_head">Why Should Freelancers Use The Depo App?</h1>
                        <p className="second_p">Need Content</p>

                        <button className="app_store">In The App Store</button>
                        <button className="play_store">In The Play Store</button>
                        <p className="second_p_f">Need Content</p>

                        <img className="lawyer_phone" src={'#'} alt=""/>
                    </Card>

                </div>

                <div>
                    <Card className="card2">
                        <h1 className="lawyer_tools_header">Unique Tools For Our Freelancers</h1>
                        <h2 className="first_subheader">(Insert Tool)</h2>
                        <p className="first_subp">Description of Tool.</p>
                        <img className='analytic_pic' src={'#'} alt=''/>

                    </Card>
                </div>

                <div>
                    <Card className="card2">
                        <h2 className="second_subheader">Insert Tool</h2>
                        <p className="second_subp">Description of Tool</p>
                        <img className='assistance_pic' src={'#'} alt=''/>

                    </Card>
                </div>

                <div>
                    <Card className="card2">
                        <h2 className="third_subheader">Insert Tool</h2>
                        <p className="third_subp">Description of tool</p>
                        <img className='files_pic' src={'#'} alt=''/>

                    </Card>
                </div>

                <div>
                    <Card className="card3">
                        <h1 className="fourth_head">Additional Services For The Future</h1>
                        <h2 className="fourth_subheader">Insert Future Freelancer Services</h2>
                        <h2 className="fifth_subheader">Insert Future Freelancer Services</h2>
                        <h2 className="sixth_subheader">Insert Future Freelancer Services</h2>

                    </Card>
                </div>

            </Container>






        </>
    )
}


export default Freelancers_Content