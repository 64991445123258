import React from "react";


import './About_Us.css';
import {Card} from "react-bootstrap";

import Container from "react-bootstrap/Container";



import banner1 from '../img/hp_banner.jpg';
import mission_logo from '../img/ab_ic2.png';
import mission_image from '../img/ab_pic1.jpg';
import vision_logo from '../img/ab_ic1.png';
import vision_image from '../img/ab_pic2.jpg';
import value_logo from '../img/ab_ic3.png';
import green_check from '../img/green-check.png';
import third_banner from "../img/bg_download.jpg";
import proimg from "../img/professional.jpg";
import security from "../img/security_laptop.jpg";
import extra from "../img/extraMile_img.jpg";




function About_Us_Content(){
    return(
        <>
            <Container fluid style={{overflow: "hidden"}}>
                <div className="row">
                    <div>
                        <Card style={{border:"none"}} className='banner1_card'>
                            <Card.Img src={banner1} alt='laptop' className="banner1 "/>
                            <h1 className="banner1_head">About Us</h1>

                        </Card>
                        <div className="box2"></div>
                        <h1 className="banner1-5_head">About Us</h1>
                    </div>
                </div>

                <div>
                    <Card className='story_card'>
                        <h1 className='story_head'>Our Story</h1>
                        <p className='story_p'>The Depo App was created because we wanted to normalize the random nature of depositions. Lawyers
                            and court reporters <br className='disappear'/> face timing challenges scheduling depositions and this in-efficiency costs everybody
                            money. We came up with a proprietary <br className='disappear'/> patent pending scheduling algorithm that better manages the
                            logistics so lawyers can save money and court reporters can <br className='disappear'/> optimize their earning capacity.</p>
                    </Card>
                </div>
                <div>
                    <Card className='mission_card'>
                        <h1 className='mission_head'>Our Mission</h1>
                        <img className='mission_logo' src={mission_logo} alt='mission arrow'/>
                        <p className='mission_p'>The DepoApp is a platform dedicated to bringing together legal
                            professionals and the people that <br className='disappear'/> provide services for their depositions
                            such as court reporters, interpreters, videographers and video <br className='disappear'/> tech
                            personnel. The process is streamlined through our patent pending
                            application with a one in a <br className='disappear'/> kind scheduling algorithm that improves cost
                            efficiency for legal professionals and gives the court <br className='disappear'/> reporters, etal , the
                            ability to earn more money. Our app does so by connecting everyone
                            directly to <br className='disappear'/> each other and eliminating the levels in between
                            communication amongst everyone involved in a <br className='disappear'/> deposition.</p>
                        <img className='mission_img' src={mission_image} alt='woman showing something to other woman on computer'/>

                    </Card>

                </div>

                <div>
                    <Card className='vision_card'>
                        <h1 className='vision_head'>Our Vision</h1>
                        <img className='vision_logo' src={vision_logo} alt='vision eye'/>
                        <p className='vision_p'>We see the DepoApp as a platform for everything needed for depositions including <br className='disappear'/>
                            court reporters, videographers, interpreters, e-discovery, courtroom techs, scopists, <br className='disappear'/>
                            document services, and document storage.</p>
                        <img className='vision_img' src={vision_image}/>

                    </Card>


                </div>

                <div>
                    <Card className='values_card'>
                        <h1 className='values_head'>Our Core Values</h1>
                        <img className='values_logo' src={value_logo} alt='blue diamond'/>

                            <img src={proimg} className='value1_img'/>
                            <p className='value1'>Be Professional</p>
                            <p className='value1_p'>Explain what it means to be professional</p>




                            <img src={security} className='value2_img'/>

                            <p className='value2'>Uphold Security</p>
                            <p className='value2_p'>Highlight the importance of Secuirty</p>





                            <img src={extra} className='value3_img'/>
                            <p className='value3'>Always Go The Extra Mile</p>
                            <p className='value3_p'>Showcase the rewards of going the Extra Mile</p>
                    </Card>
                </div>



                <div className='better_card'>
                    <h2 className='betterhead1'>A better way of working</h2>
                    <h1 className='betterhead2'>for court reporters and lawyers</h1>
                    <p className='better_p'>Our founders learned while working in the field that around 70% of the court reporters <br className='disappear'/> providing
                        services for depositions are freelancers working for multiple parties. The <br className='disappear'/> addition of middle men
                        to fill the court reporters working schedules adds costs for the <br className='disappear'/> lawyers while garnishing the
                        earnings of the court reporters doing 99% of the work. By <br className='disappear'/> using the DepoApp.com platform and
                        app lawyers get access to many freelance court <br className='disappear'/> reporters at prices within their specified budgets
                        and by dealing direct the lawyers save <br className='disappear'/> money.</p>
                    <div className='horizontal-line'/>
                    <img className='check6' src={green_check} alt = 'green check'/>
                    <p className='check6_p'>Lawyers save money and have central management of financials, documentation <br className='disappear'/>
                        services, security, and testimony with analytics by case, witness, and court reporter.</p>
                    <img className='check7' src={green_check} alt = 'green check'/>
                    <p className='check7_p'>Freelancers make more money cause of the direct relationship and a central<br/>
                        repository of lawyers looking for deposition services.</p>




                </div>


                <div>
                    <Card>
                        <Card.Img src={third_banner} className="download_banner" />
                        <h1 className='download_head'>Download Today!</h1>
                        <p className='download_p'>Lorem Ipsum is simply dummy text of the printing and typesetting <br className='disappear'/>
                            industry. Lorem Ipsum is simply dummy text of the printing and <br className='disappear'/>
                            typesetting industry.</p>
                        <button className='down_app'>In The App Store</button>
                        <button className='down_play'>In The Play Store</button>
                        <div className="box3"></div>


                    </Card>

                </div>






            </Container>

        </>
    )
}

export default About_Us_Content

