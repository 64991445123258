import React from 'react';


import './Tech_Support.css';
import {Card} from "react-bootstrap";

import Container from "react-bootstrap/Container";

import banner1 from "../img/hp_banner.jpg";




function Tech_Support_Content(){
    return(
        <>
            <Container fluid style={{overflow: "hidden"}}>
                <div className="row">
                    <div>
                        <Card style={{border:"none"}} className='tech_banner1_card'>
                            <Card.Img src={banner1} alt='laptop' className="tech_banner1 "/>
                            <h1 className="tech_banner1_head">Tech Support</h1>
                        </Card>
                        <div className="box2"></div>
                        <h1 className="tech_banner1-5_head">Tech Support</h1>
                    </div>
                </div>
                <div>
                    <Card className='FAQ_header_card'>
                        <h1 className='FAQ_header'>Frequently Asked Questions</h1>
                    </Card>
                </div>


            </Container>
        </>
    )
}

export default Tech_Support_Content
