import Home_Content from "./Home_Content";
import Footer from "../../Footer";



function Home(){
    return(
        <>

            <Home_Content/>
            <Footer/>











        </>
    )
}

export default Home;