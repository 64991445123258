
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import Nav from './components/Nav';

import './App.css';
import Home from './components/pages/Home/Home'
import SignIn from './components/SignIn/Form'
import SignUp from './components/SignUp/Form'
import Lawyer from "./components/pages/Lawyers/Lawyers";
import Freelancer from "./components/pages/Freelancers/Freelancers";
import About_Us from "./components/pages/About_Us/About_Us";
import Our_App from "./components/pages/Our_App/Our_App";
import Contact_Us from "./components/pages/Contact_Us/Contact_Us";
import Tech_Support from "./components/pages/Tech_Support/Tech_Support";
import Terms_Of_Use from "./components/pages/Terms_Of_Use/Terms_Of_Use";
import Privacy_Policy from "./components/pages/Privacy_Policy/Privacy_Policy";



function App() {
  return (
      <>

        <Router>
            <Nav/>
            <Switch>
                <Route path = '/' exact component={Home}/>
                <Route path = '/sign-up' component={SignUp}/>
                <Route path = '/sign-in' component={SignIn}/>
                <Route path = '/lawyers' component={Lawyer}/>
                <Route path = '/freelancers' component={Freelancer}/>
                <Route path = '/about-us' component={About_Us}/>
                <Route path = '/our-app' component={Our_App}/>
                <Route path = '/contact-us' component={Contact_Us}/>
                <Route path = '/tech-support' component={Tech_Support}/>
                <Route path = '/terms-of-use' component={Terms_Of_Use}/>
                <Route path = '/privacy-policy' component={Privacy_Policy}/>







            </Switch>
        </Router>

      </>
  );
}

export default App;
