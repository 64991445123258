import React from "react";

import Footer from "../../Footer";

import Contact_Us_Content from "./Contact_Us_Content";

function Contact_Us(){
    return(
        <>
            <Contact_Us_Content/>
            <Footer/>




        </>
    )

}


export default Contact_Us;