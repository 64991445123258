import React, { useState } from 'react';


import './Tech_Support.css';
import {Button, Card} from "react-bootstrap";

import Container from "react-bootstrap/Container";
import tech_banner1 from "../img/hp_banner.jpg";
import { Faq_Data } from './FAQ_data';
import styled from 'styled-components';

import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';
import third_banner from "../img/bg_download.jpg";




const AccordionSection = styled.div`
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  background: #fff;

  @media (max-width: 800px){
    
    
    
  }
  
  
`;



const Wrap = styled.div`
  background: white;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  h1 {
    padding: 1.6666666666666667vw;
    font-size:1.6666666666666667vw;
  }
  span {
    margin-right: 1.25vw;
  }
  @media (max-width: 800px){
    
    h1{
      font-size: 20px;
    }


  }
  
  
  
`;

const Dropdown = styled.div`
  background: white;
  color: black;
  width: 100%;
  height:  5.208333333333333vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  p {
    font-size: 1.6666666666666667vw;
  }

  @media (max-width: 800px){

    height: 100px;
    
    p{
      font-size: 15px;
    }


  }
  
  
`;





function FAQ(){
    const [clicked, setClicked] = useState(false);

    const toggle = index => {
        if (clicked === index) {
            //if clicked question is already active, then close it
            return setClicked(null);
        }

        setClicked(index);
    };
    return(
        <>

            <div>
                <Card className='FAQ_card'>
                    <IconContext.Provider value={{ color: 'black', size: '25px' }}>
                        <AccordionSection>
                            <Container>
                                {Faq_Data.map((item, index) => {
                                    return (
                                        <>
                                            <Wrap onClick={() => toggle(index)} key={index}>
                                                <h1>{item.question}</h1>
                                                <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                                            </Wrap>
                                            {clicked === index ? (
                                                <Dropdown>
                                                    <p>{item.answer}</p>
                                                </Dropdown>
                                            ) : null}
                                        </>
                                    );
                                })}
                            </Container>
                        </AccordionSection>
                    </IconContext.Provider>


                </Card>
            </div>


            <div>
                <Card className='ticket_card'>
                    <h1 className='ticket_header'>Still have questions?</h1>
                    <Button href='./sign-in' className='ticket_button'>Click to open a ticket</Button>
                    <h1 className='beta_warning'>*We are in beta if you encounter any emergencies call us directly at +267-246-8849 or<br/>
                        fill in the form with details and we will contact you within 48 hours</h1>

                </Card>
            </div>

            <Card>
                <Card.Img src={third_banner} className="download_banner" />
                <h1 className='download_head'>Download Today!</h1>
                <p className='download_p'>Lorem Ipsum is simply dummy text of the printing and typesetting<br/>
                    industry. Lorem Ipsum is simply dummy text of the printing and<br/>
                    typesetting industry.</p>
                <button className='down_app'>In The App Store</button>
                <button className='down_play'>In The Play Store</button>
                <div className="box3"></div>


            </Card>







        </>
    )
}

export default FAQ