import React from "react";

import Footer from "../../Footer";

import About_Us_Content from "./About_Us_Content";

function About_Us(){
    return(
        <>
            <About_Us_Content/>
            <Footer/>

        </>
    )

}


export default About_Us
