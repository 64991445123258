export const Faq_Data = [
    {
        question: 'What do you call a dog magician?',
        answer: 'A labracadabrador.'
    },
    {
        question: 'What do you call a funny mountain?',
        answer: 'Hill-arious.'
    },
    {
        question: 'What did the astronaut say when he crashed into the moon?',
        answer: 'I Apollo-gize.'
    }
];