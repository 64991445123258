import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import logo from "./logo_small.png";

function Footer() {
    return (
        <div className='footer-container'>
            <div class='footer-links'>
                <div className='footer-link-wrapper'>
                    <div class='footer-link-items'>
                        <h2>Users</h2>
                        <Link to='/lawyers'>Lawyers</Link>
                        <Link to='/freelancers'>Depo Freelancers</Link>

                    </div>
                    <div class='footer-link-items'>
                        <h2>Company</h2>
                        <Link to='/about-us'>About Us</Link>
                        <Link to='/contact-us'>Contact Us</Link>
                        <Link to='/tech-support'>Tech Support</Link>
                        <Link to='/'>Admin Portal</Link>
                        <Link to='/privacy-policy'>Privacy Policy</Link>
                        <Link to='/terms-of-use'>Terms of Use</Link>

                    </div>
                </div>

            </div>
            <section class='social-media'>
                <div class='social-media-wrap'>
                    <div class='footer-logo'>
                        <Link to='/' className='social-logo'>
                            <img src={logo} alt={'Depo App Logo'}/>

                        </Link>
                    </div>
                    <small class='website-rights'>Depo App Company © 2022</small>
                    <div class='social-icons'>
                        <a
                            className='social-icon-link facebook'
                            href = "https://www.facebook.com/depoapp"
                            target='_blank'
                            aria-label='Facebook'
                        >
                            <i className='fab fa-facebook-f' />
                        </a>

                        <Link
                            class='social-icon-link twitter'
                            to='/'
                            target='_blank'
                            aria-label='Twitter'
                        >
                            <i class='fab fa-twitter' />
                        </Link>
                        <Link
                            class='social-icon-link twitter'
                            to='/'
                            target='_blank'
                            aria-label='LinkedIn'
                        >
                            <i class='fab fa-linkedin' />
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Footer;