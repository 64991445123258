import React from "react";



import Footer from "../../Footer";

import Freelancers_Content from "./Freelancers_Content";

function Freelancer(){
    return(
        <>
            <Freelancers_Content/>
            <Footer/>
        </>
    )

}


export default Freelancer

