import React from "react";

import 'bootstrap/dist/css/bootstrap.css';

import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';


//First Section Images
import first_banner from '../img/hp_banner.jpg';
import first_phone from '../img/hp_banner_pic.png';
import handshake from '../img/hp_ic1.png';
import toolbox from '../img/hp_ic2.png';
import hand_money from '../img/hp_ic3.png';

//Second Section Images
import second_phone from '../img/hp_pic2.jpg';
import blue_tick from '../img/hp_tick.png';

//Third Section Images
import third_banner from '../img/Download today crop.png';

const Styles = styled.div`
  *{
    font-family: 'Roboto', sans-serif;
  }
  .home_banner1{
    
    position: relative;
    display: block;
    
    
    

  }

  .first_phone{
    position: absolute;
    
    bottom: 0%;
    left: 60%; /*or whatever top/left values you need*/
    width: 30%;
    

  }

  .first_text{
    left: 20%;
    top: 23%;
    position: absolute;
    width: 100%;
    
    color: white;
    font-size: 1.8229166666666667vw;
    font-weight: 200;
    line-height: 1.3;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 50%);
    
  }
  .schedule_button{
    position: absolute;
    color: #fff;
    background-color: #4abdcf;
    padding: 0.3% 2.5%;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    left: 18%;
    top: 48%;
    font-size: 0.9375vw;
    line-height:  1.2485811577752555vw;
    font-weight: 200;
    float: left;
    



  }

  .schedule_button:hover{
    background-color: #000066;
    border-radius: 4px;
    transition: all .5s ease-out;

  }


  .work_button{
    position: absolute;
    color: #fff;
    background-color: #267f8d;
    padding: 0.3% 2%;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    left: 30%;
    top: 48%;
    font-size: 0.9375vw;
    line-height:  1.2485811577752555vw;
    font-weight: 200;
    float: left;

  }
  .work_button:hover{
    background-color: #000066;
    border-radius: 4px;
    transition: all .5s ease-out;

  }

  .app_store_button{
    position: absolute;
    
    background-color: transparent;
    padding: 1.5% 6%;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    left: 65.5%;
    top: 45%;
    float: left;
  }
  .app_store_button:hover{
    background-color: #10c8ee;
    border-radius: 4px;
    transition: all 1s ease-out;
    opacity: 0.2;
  }

  .play_store_button{
    position: absolute;
    background-color: transparent;
    padding: 1.5% 6%;
    border-radius: 1vw;
    outline: none;
    border: none;
    cursor: pointer;
    left: 65.5%;
    top: 52%;
    float: left;

  }
  .play_store_button:hover{
    background-color: #0a7388;
    border-radius: 4px;
    transition: all 1s ease-out;
    opacity: 0.2;
  }

  .black_rectangle{
    position: absolute;
    width:100%;
    height:25%;
    top: 76%;
    border: 1px solid #000;
    background-color: black;
    opacity: 0.6;
  }

  

  .handshake{
    position: absolute;
    width: 2.5%;
    
    top: 78%;
    left: 26.3%;

  }
  .handshake_heading{
    position: absolute;
    color: #49becf;
    top: 85%;
    left: 23%;
    font-size:  0.8333333333333334vw;
    font-weight: 200;
  }

  .handshake_p{
    position: absolute;
    top: 88%;
    left: 21%;
    color: #fff;
    font-size:  0.8333333333333334vw;
    line-height: 200%;
    text-align: center;
    
  }

  .l1{
    position: absolute;
    width:0.01%;
    height:24%;
    top: 76%;
    background-color: white;
    left: 40%;

  }

  .l2{
    position: absolute;

    width:0.01%;
    height:24%;
    top: 76%;
    background-color: white;
    left: 64%;
  }

  .toolbox{
    width: 2.5%;
    position: absolute;
    top: 78%;
    left: 51%;
    
    
  }
  .toolbox_heading{
    position: absolute;
    color: #49becf;
    top: 84%;
    left: 49%;
    font-size: 0.8333333333333334vw;
    font-weight: 200;
  }
  .toolbox_p{
    position: absolute;
    top: 86.5%;
    left: 43%;
    color: #fff;
    line-height: 200%;
    font-size:  0.8333333333333334vw;
    text-align: center;


  }

  .hand_money{
    width: 2.5%;
    position: absolute;

    top: 78%;
    left: 76.8%
  }
  .hand_heading{
    position: absolute;
    color: #49becf;
    top: 85%;
    left: 76%;
    font-size: 0.8333333333333334vw;
    font-weight: 200;
  }
  .hand_p{
    position: absolute;
    top: 88%;
    left: 70%;
    color: #fff;
    line-height: 200%;
    font-size:  0.8333333333333334vw;
    text-align: center;

  }

  /* Second Section */

  .second_phone{
    position: relative;
    width: 17%;
    top: 20%;
    left: 18%;
  }

  .second_header{
    position: relative;
    top: -35%;
    left: 46%;
    font-size: 1.9863791146424516vw;
    font-weight: bold;
    color: #173C67;

  }


  .tick_1{
    position: relative;
    width: 1%;
    top: -30%;
    left: 45%;


  }
  .tick_2{
    position: relative;
    width: 1%;
    top: -10%;
    left: 45%;
  }
  .tick_3{
    position: relative;
    width: 1%;
    top: 10%;
    left: 45%;
  }


  .head_2{
    position: relative;
    color: #173C67;
    top: -34.8%;
    left: 48%;
    font-weight: bold;
    font-size: 1.1350737797956867vw;

  }

  .head_2_p{
    position: relative;
    font-size: 1vw;
    line-height: 200%;
    color: #6a6a6a;
    top: -35%;
    left: 48%;
  }

  .head_3{
    position: relative;
    
    color: #173C67;
    top: -26.5%;
    left: 48%;
    font-size: 1.1350737797956867vw;
    font-weight: bold;
  }
  .head_3_p{
    position: relative;
    line-height: 200%;
    font-size: 1vw;
    color: #6a6a6a;
    top: -27%;
    left: 48%;
  }

  .head_4{
    position: relative;
    color: #173C67;
    top: -21%;
    font-weight: bold;
    left: 48%;
    font-size: 1.1350737797956867vw;
  }
  .head_4_p{
    position: relative;
    line-height: 200%;
    font-size: 1vw;
    color: #6a6a6a;
    top: -21%;
    left: 48%;
  }


  .third_banner{
    position: relative;
    display: block;
    filter: brightness(75%);
    width: 100%;

  }
  
  
  .head_5{
    position: absolute;
    color: white;
    font-size: 1.7026106696935301vw;
    font-weight: bold;
    left: 50%;
    top: 35%;
  }
  .head_5_p{
    position: absolute;
    color: white;
    font-size: 0.9648127128263337vw;
    left: 50%;
    top: 45%;
    line-height: 200%;
  }
  .app_store_button_2{
    position: absolute;
    
    color: #fff;
    background-color: #4abdcf;
    padding: 0.8% 2%;
    border-radius: 0.22701475595913734vw;
    outline: none;
    border: none;
    cursor: pointer;
    left: 50%;
    top: 70%;
    font-size: 1.0215664018161181vw;
    line-height: 100%;


  }
  .app_store_button_2:hover{
    background-color: #000066;
    border-radius: 0.22701475595913734vw;
    transition: all .5s ease-out;
  }

  .play_store_button_2{
    position: absolute;
    color: #fff;
    background-color: #267f8d;
    padding: 0.8% 2%;
    border-radius: 0.22701475595913734vw;
    outline: none;
    border: none;
    cursor: pointer;
    left: 65%;
    top: 70%;
    font-size: 1.0215664018161181vw;
    line-height: 100%;
  }
  .play_store_button_2:hover{
    background-color: #000066;
    border-radius: 0.22701475595913734vw;
    transition: all .5s ease-out;
  }

  .container-fluid {
    padding-right:0;
    padding-left:0;
    margin-right:auto;
    margin-left:auto;
    
    
  }


  @media (max-width: 800px){
    .disappear{
      display:none;
    }
    .home_banner1{
      width: 1500px;
      right: 500px;
    }
    .first_text{
      
      top: 40px;
      font-size: 25px;
      text-align: center;
      left: 0px;
      width: 100%;
    }
    .schedule_button{
      padding: 5px 48px;
      font-size: 15px;
      top: 200px;
      line-height: 150%;
      left: 2%;
      width: 45%;
      
      
    }
    .work_button{
      padding: 5px 45px;
      font-size: 15px;
      top: 200px;
      line-height: 150%;
      left: 50%;
      width: 47%;
      
    }
    .first_phone{
      width: 325px;
      left: 65px;
      top: 250px;
    }
    
    .app_store_button{
      top: 458px;
      left: 125px;
      width: 126px;
      height: 4%;
      
    }
    
    .play_store_button{
      top: 495px;
      left: 125px;
      width: 126px;
      height: 4%;
      
      
    }
    
    .black_rectangle{
      opacity: 1;
      top: 720px;
      width: 100%;
      height: 360px;
    }
    
    .handshake{
      width: 40px;
      left: 45%;
      top: 730px;
    }
    
    .handshake_heading{
      font-size: 10px;
      left: 36%;
      top: 770px;
      font-weight: bold;
      
    }
    .handshake_p{
      font-size: 10px;
      top: 790px;
      left: 30%;
      line-height: 15px;
      text-align: center;
    }
    .l1{
      border-bottom: 1px solid gray;
      top: 830px;
      height: 0;
      width: 100%;
      left: 0px;

    }
    
    .toolbox{
      width: 40px;
      left: 45%;
      top: 840px;
      
    }
    .toolbox_heading{
      font-size: 10px;
      left: 40%;
      top: 880px;
      font-weight: bold;
    }
    
    .toolbox_p{
      font-size: 10px;
      top: 900px;
      left: 23%;
      line-height: 15px;
      text-align: center;
      
    }
    .l2{
      border-bottom: 1px solid gray;
      top: 960px;
      height: 0;
      width: 100%;
      left: 0px;
      
    }
    
    .hand_money{
      width: 40px;
      left: 44.5%;
      top: 970px;
      
    }
    
    .hand_heading{
      font-size: 10px;
      left: 43%;
      top: 1010px;
      font-weight: bold;
      
    }
    .hand_p{
      font-size: 10px;
      top: 1030px;
      left: 28%;
      line-height: 15px;
      text-align: center;
    }
    .c-1{
      height: 1080px;
    }
    
    .second_phone{
      width: 120px;
      left: 140px;
      top: 10px;
    }
    .second_header{
      font-size: 20px;
      left: 5%;
      top: 40px;
      
    }
    .tick_1{
      width: 13px;
      left: 5%;
      top: 60px;
    }
    .head_2{
      font-size: 12px;
      left: 10%;
      top: 20px;
      font-weight: bold;
    }
    
    .head_2_p{
      font-size: 9px;
      left: 10%;
      top: 20px;
      text-align: left;
    }
    
    .tick_2{
      width: 13px;
      left: 5%;
      top: 160px;
    }
    .head_3{
      font-size: 12px;
      left: 10%;
      top: 42px;
      font-weight: bold;
      
    }
    .head_3_p{
      font-size: 9px;
      left: 10%;
      top: 40px;
      text-align: left;
    }
    
    .tick_3{
      width: 13px;
      left: 5%;
      top: 292px;
    }
    .head_4{
      font-size: 12px;
      left: 10%;
      top: 60px;
      font-weight: bold;
      
    }
    .head_4_p{
      font-size: 9px;
      left: 10%;
      top: 58px;
      text-align: left;
    }

    .c-2{
      height: 700px;

    }
    
    
    .third_banner{
      width: 1300px;
      right: 500px;
    }
    
    
    .head_5{
      top: 150px;
      font-size: 25px;
      left: 30px;
      width: 100%;
      
    }
    .head_5_p{
      font-size: 12px;
      left: 7%;
      top: 200px;
      text-align: left;
      
    }
    
    .app_store_button_2{
      padding: 5px 40px;
      font-size: 15px;
      top: 300px;
      left: 5%;
      width: 40%;
      border-radius: 4px;
      
    }
    
    .play_store_button_2{
      padding: 5px 40px;
      font-size: 15px;
      top: 300px;
      left: 55%;
      width: 40%;
      border-radius: 4px;
      
    }
    
    
    .c-3{
      height: 400px;
      
    }
    
    
    
    
    
    
    
    
    
    
  }

`




function Home_Content(){







    return(
        <Styles>
        <Container fluid style={{overflow: "hidden"}}>
            <div>
                <Card className="c-1">
                <Card.Img src={first_banner} className="home_banner1"/>
                    <Card.Img src={first_phone} className="first_phone"/>

                    <p className="first_text">An app for lawyers to<br/>schedule deposition<br/> services directly from<br/>deposition freelancers.</p>
                    <button className="schedule_button" onClick={'#'}>Schedule<br/>(Lawyers)</button>
                    <button className="work_button" onClick={'#'}>Work<br/>(Freelancers)</button>
                    <button className="app_store_button"/>
                    <button className='play_store_button'/>

                    <div className='black_rectangle'/>
                    <img src={handshake} alt={'Blue handshake'} className='handshake'/>
                    <p className="handshake_heading">Save Money Going Direct</p>
                    <p className="handshake_p">See savings up to 20% when dealing<br/> directly with freelance court reporters</p>
                    <div className='l1'/>
                    <div className='l2'/>
                    <img src={toolbox} alt={'Blue toolbox'} className='toolbox'/>
                    <p className='toolbox_heading'>Tools for Success</p>
                    <p className='toolbox_p'>Get services such as document recovery, case
                        and<br/> witness deposition financial analytics, archived<br/> recordings, and find different types of
                        deposition<br/>support freelancers</p>
                    <img src={hand_money} alt={'Blue hand with coin'} className='hand_money'/>
                    <p className='hand_heading'>Earn More</p>
                    <p className='hand_p'>The Depo App can provide an additional<br/> source of revenue for deposition freelancers.</p>
            </Card>
            </div>

            <div className="row">
                <Card className="c-2">
                    <Card.Img src={second_phone} className="second_phone"/>
                    <h1 className='second_header'>Discover The Features</h1>
                    <img src={blue_tick} alt={'Blue Check'} className='tick_1'/>
                    <img src={blue_tick} alt={'Blue Check'} className='tick_2'/>
                    <img src={blue_tick} alt={'Blue Check'} className='tick_3'/>

                    <h2 className='head_2'>Fast and simple scheduling and billing</h2>
                    <p className='head_2_p'>Our patent pending scheduling algorithm will allow law firms and lawyers to save<br/> money on
                        deposition transcripts and allow our court reporters to optimize their<br className='appear'/> availability <br className='disappear'/> and earn
                        money. It's a win-win for everyone!</p>

                    <h2 className='head_3'>Your data is backed up safely and securely</h2>
                    <p className='head_3_p'>Lawyers benefit by dealing directly with deposition freelancers so less people are<br className='appear'/> interacting <br className='disappear'/>
                        with chain of custody legal documents.The Depo App can also provide <br className='appear'/> backup and document<br className='disappear'/> recover services with a 1-2-3 backup
                        philosophy in place to <br className='appear'/> protect testimony and exhibits.<br className='disappear'/> WE USE TOP OF THE LINE DATA
                        SECURITY TO <br className='appear'/> PROTECT AND PRESERVE CONFIDENTIALITY</p>

                    <h2 className='head_4'>Just in time deposition support freelancers</h2>
                    <p className='head_4_p'>Court Reporters that sign up to our platform are available at the last minute to fulfill <br className='appear'/> emergency <br className='disappear'/>
                        depositions. The patent pending scheduling algorithm provides freelancers <br className='appear'/> at a moments notice</p>

                </Card>
            </div>

            <div className="row">
                <Card className="c-3">
                    <Card.Img src={third_banner} className="third_banner" />
                    <h1 className='head_5'>Download The App!</h1>
                    <p className='head_5_p'>Lorem Ipsum is simply dummy text of the printing and typesetting<br/>
                        industry. Lorem Ipsum is simply dummy text of the printing and<br/>
                        typesetting industry.</p>
                    <button className='app_store_button_2'>In The App Store</button>
                    <button className='play_store_button_2'>In The Play Store</button>


                </Card>

            </div>



        </Container>

        </Styles>
    )
}

export default Home_Content;