import React from "react";


import './Our_App.css';
import {Card} from "react-bootstrap";

import Container from "react-bootstrap/Container";

import app_banner from "../img/banner_3.jpg"
import third_banner from "../img/bg_download.jpg";



function Our_App_Content(){
    return(
        <>
            <Container fluid style={{overflow: "hidden"}}>
                <div className='row'>
                    <div>
                        <Card style={{border:"none"}} className='app_banner_card'>
                            <Card.Img src={app_banner} alt='laptop' className="app_banner"/>
                            <h1 className="app_banner_head">Our App</h1>
                        </Card>
                    </div>
                </div>

                <div>
                    <Card className='app_header1_card'>
                        <h1 className='app_header_1'>What is the Depo App?</h1>
                        <p className='app_header1_p'>The Depo App will become the place for your team to easily manage dedicated litigation and
                            trial support specialists to ensure that you receive what you need on time<br/> and with precise
                            attention to detail. Our goal is to handle the technology so that you can focus your attention on
                            winning your case.</p>

                    </Card>
                </div>
                <div>
                    <Card className='app_header2_card'>
                        <h1 className='app_header_2'>Why use the Depo App?</h1>
                        <p className='app_header2_p'>The litigation support specialists serving our lawyers will be well vetted and we will implement a
                            grading system that will assure you get what your looking for.<br/> This is only the start to our plans
                            to provide cutting-edge technical advancements in the industry that can seamlessly integrate
                            and augment your litigation teams needs.<br/> We are proudly providing access to our beta testing
                            group in the Tri-State area, and based in Philadelphia, PA. We are accepting interested beta
                            test participants in the<br/> eastern PA, NJ, DE, and NY areas. If you would like to jump in and see
                            what we have cooking then apply below.<br/> <a href="#">Click here to signup for beta.</a></p>



                    </Card>
                </div>

                <Card className="app_download_card">
                    <Card.Img src={third_banner} className="app_download_banner" />
                    <h1 className='app_download_head'>Download Today!</h1>
                    <p className='app_download_p'>Lorem Ipsum is simply dummy text of the printing and typesetting<br/>
                        industry. Lorem Ipsum is simply dummy text of the printing and<br/>
                        typesetting industry.</p>
                    <button className='app_down_app'>In The App Store</button>
                    <button className='app_down_play'>In The Play Store</button>


                </Card>

            </Container>
        </>
    )
}

export default Our_App_Content;