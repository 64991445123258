import React from "react";



import Footer from "../../Footer";

import Privacy_Policy_Content from "./Privacy_Policy_Content";


function Privacy_Policy(){
    return(
        <>
            <Privacy_Policy_Content/>
            <Footer/>










        </>
    )
}

export default Privacy_Policy;