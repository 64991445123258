import React from "react";
import "./Privacy_Policy.css";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.css';
import first_banner from "../img/banner_3.jpg";

function Privacy_Policy_Content(){
    return(
        <>
            <Container fluid style={{overflow: "hidden"}} className="test">

                <div className="row">

                    <div>
                        <Card style={{border:"none"}}>
                            <Card.Img src={first_banner} alt='Guy with laptop' className="PP_banner1"/>
                            <h1 className="PP_head1">Privacy Policy</h1>
                            <div className="box5"></div>
                        </Card>
                    </div>
                    <div>
                        <Card className='PP_card1'>
                            <h1 className='PP_head2'>Privacy Policy</h1>
                            <p className='PP_p1'>We only use your personal information to provide you the Contently services or to communicate with you about the services or the Website.

                                With respect to any documents you may choose to upload to Contently, we take the privacy and confidentiality of such documents seriously. We encrypt all documents, and permanently delete any redacted edits you make to documents. If you choose to make a document public, we recommend you redact any and all references to people and addresses, as we can’t protect public data and we are not responsible for any violation of privacy law you may be liable for.

                                We employ industry standard techniques to protect against unauthorized access of data about you that we store, including personal information.</p>

                        </Card>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Privacy_Policy_Content