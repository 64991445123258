import React from "react";
import {Link} from "react-router-dom";

import logo from './logo_small.png';



import styled from 'styled-components';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';



const Styles = styled.div`
    
  
  
  .button{
    background-color: #30b849;
    color: white;
    padding: 5px 10px;
    border: 1px solid #30b849;
    transition: all 0.3s ease-out;
    margin-right: 5%;

    &:hover{
      background: #000066;
      color: white;
      transition: all 0.3s ease-out;
    }
  }

  .nav-item.dropdown:hover .dropdown-menu {
    display: block;
    
  }
  
  .logo{
    margin-left: 10%;
  }
  
  .link{
    text-decoration: none;
    color: black;
    margin-top: 0.6%;
  }
  .link_signin{
    text-decoration: none;
    color: black;
    margin-top: 0.5%;
    margin-right: 1%;
  }
  .link_signup{
    text-decoration: none;
    color: white;
    margin-top: 0.6%;
    
  }
  
 


  

  
  
  
  
`;



function OffcanvasExample() {
    return (
        
        <Styles>
            {['xl'].map((expand) => (
                
                <Navbar key={expand} bg="white" expand={expand} className="navbar">
                    <Container fluid>
                        <Navbar.Brand href="/"><img className='logo' src={logo} alt="Depo App Logo"/></Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    <img src={logo} alt="Depo App Logo"/>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-lg-end flex-grow-1">
                                    <Link to="/" className="ps-3 pe-5 fs-5 link text-decoration-none text-black">Home</Link>

                                    <NavDropdown


                                        className="ps-3 pe-5 fs-5"
                                        title={
                                            <span className="text-dark">App</span>
                                        }
                                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                                        renderMenuOnMount={true}
                                    >
                                        <NavDropdown.Item href="/our-app">
                                            <Link className = "link text-decoration-none text-black" to="/our-app">Our App</Link>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item>
                                            <Link className = "link text-decoration-none text-black" to="/lawyers">Lawyers</Link>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item>
                                            <Link className = "link text-decoration-none text-black" to="/freelancers">Freelancers</Link>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown
                                        className="ps-3 pe-5 fs-5"
                                        title={
                                            <span className="text-dark">Company</span>
                                        }
                                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                                        renderMenuOnMount={true}
                                    >
                                        <NavDropdown.Item>
                                            <Link className = "link text-decoration-none text-black" to="/about-us">About Us</Link>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item>
                                            <Link className = "link text-decoration-none text-black" to="/contact-us">Contact Us</Link>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item>
                                            <Link className = "link text-decoration-none text-black" to="/tech-support">Tech Support</Link>
                                        </NavDropdown.Item>
                                    </NavDropdown>

                                    <Link  to="/sign-in" className="fs-5 link_signin text-decoration-none text-black">Sign In</Link>
                                    <Button className=" button fs-5 btn-success" >
                                        <Link className = "link_signup text-decoration-none text-white" to="/sign-up">Sign Up</Link>
                                    </Button>




                                    
                                </Nav>

                                
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>

            ))}
        </Styles>
    );
}

export default OffcanvasExample;